// this file is generated automatically, do not change anything manually in the contents of this file

export const ICONS = {
    "arrow-left": { w: 448, h: 512 },
    "arrow-right": { w: 448, h: 512 },
    "calendar": { w: 448, h: 512 },
    "camera": { w: 512, h: 512 },
    "caretDown": { w: 320, h: 512 },
    "clock": { w: 512, h: 512 },
    "bars": { w: 448, h: 512 },
    "check": { w: 512, h: 512 },
    "chevronDown": { w: 512, h: 512 },
    "chevronLeft": { w: 384, h: 512 },
    "chevronsLeft": { w: 512, h: 512 },
    "chevronRight": { w: 384, h: 512 },
    "chevronsRight": { w: 512, h: 512 },
    "chevronUp": { w: 512, h: 512 },
    "cloud": { w: 640, h: 512 },
    "copy": { w: 512, h: 512 },
    "ellipsis-vertical": { w: 128, h: 512 },
    "file": { w: 384, h: 512 },
    "imageFile": { w: 384, h: 512 },
    "triangle-exclamation": { w: 512, h: 512 },
    "user": { w: 448, h: 512 },
    "videoFile": { w: 384, h: 512 },
    "xmark": { w: 384, h: 512 },
    "xmarkLarge": { w: 448, h: 512 },
    "logo": { w: 40, h: 40 },
    "minus": { w: 18, h: 20 },
    "done": { w: 32, h: 32 },
    "back": { w: 44, h: 44 },
    "phone": { w: 44, h: 44 },
    "at": { w: 44, h: 44 },
    "leaf": { w: 44, h: 44 },
    "banknote": { w: 44, h: 44 },
    "house": { w: 44, h: 44 },
    "plus": { w: 20, h: 20 },
    "bulb": { w: 53, h: 44 },
    "money": { w: 48, h: 41 },
} as const;

export type IconsType = keyof typeof ICONS;

declare module "@uxf/ui/icon/theme" {
    interface IconsSet {
        "arrow-left": true;
        "arrow-right": true;
        "calendar": true;
        "camera": true;
        "caretDown": true;
        "clock": true;
        "bars": true;
        "check": true;
        "chevronDown": true;
        "chevronLeft": true;
        "chevronsLeft": true;
        "chevronRight": true;
        "chevronsRight": true;
        "chevronUp": true;
        "cloud": true;
        "copy": true;
        "ellipsis-vertical": true;
        "file": true;
        "imageFile": true;
        "triangle-exclamation": true;
        "user": true;
        "videoFile": true;
        "xmark": true;
        "xmarkLarge": true;
        "logo": true;
        "minus": true;
        "done": true;
        "back": true;
        "phone": true;
        "at": true;
        "leaf": true;
        "banknote": true;
        "house": true;
        "plus": true;
        "bulb": true;
        "money": true;
    }
}
