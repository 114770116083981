import { ApolloProvider } from "@apollo/client";
import { config } from "@config/config";
import { ICONS } from "@config/icons";
import { ICON_SPRITE, ICONS_VERSION } from "@config/icons-config";
import { twScreens } from "@config/tw-tokens/tw-screens";
import { LocalizeProvider } from "@localize";
import localFont from "@next/font/local";
import { useApollo } from "@shared/lib/apollo/apollo-client";
import "@ui/styles/globals.css";
import { COLOR_SCHEME_DEFAULTS } from "@uxf/ui/color-scheme";
import { UiContextProvider, UiContextType } from "@uxf/ui/context";
import { FlashMessages, getFlashMessagesRef } from "@uxf/ui/flash-messages";
import { Modal } from "@uxf/ui/modal/modal";
import { getModalRef } from "@uxf/ui/modal/modal-service";
import { locale } from "dayjs";
import "dayjs/locale/cs";
import { AppProps } from "next/app";
import Head from "next/head";

locale("cs");

const UI_PROVIDER_CONFIG: UiContextType = {
    colorScheme: {
        defaultColorScheme: "light",
        getUserSettings: COLOR_SCHEME_DEFAULTS.getUserSettings,
        respectSystemSetting: false,
        respectUserSettings: false,
        setUserSettings: COLOR_SCHEME_DEFAULTS.setUserSettings,
    },
    domain: config.FRONTEND_URL ?? "",
    icon: { spriteFilePath: `${ICON_SPRITE}?v=${ICONS_VERSION}`, iconsConfig: ICONS },
    rasterImage: { breakpoints: twScreens },
};

const fontCodecPro = localFont({
    src: [
        {
            path: "../ui/fonts/codecpro-regular-webfont.woff2",
            weight: "400",
            style: "normal",
        },
        {
            path: "../ui/fonts/codecpro-bold-webfont.woff2",
            weight: "500",
            style: "normal",
        },
        {
            path: "../ui/fonts/codecpro-extrabold-webfont.woff2",
            weight: "600",
            style: "normal",
        },
    ],
    display: "swap",
    variable: "--font-codec-pro",
});

function UxfApp(props: AppProps) {
    const apollo = useApollo(props.pageProps);

    return (
        <>
            <style jsx global>
                {`
                    :root {
                        --font-codec-pro: ${fontCodecPro.style.fontFamily};
                    }
                `}
            </style>
            <Head>
                <meta charSet="utf-8" />
                <meta
                    name="viewport"
                    content="minimum-scale=1, initial-scale=1, width=device-width, shrink-to-fit=no"
                />
            </Head>
            <ApolloProvider client={apollo}>
                <LocalizeProvider value="cs">
                    <UiContextProvider value={UI_PROVIDER_CONFIG}>
                        <props.Component {...props.pageProps} />
                        <Modal ref={getModalRef()} />
                        <FlashMessages ref={getFlashMessagesRef()} />
                    </UiContextProvider>
                </LocalizeProvider>
            </ApolloProvider>
        </>
    );
}

export default UxfApp;
